import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { get } from "lodash";
import { Button, notification, Progress } from "antd";

import DetailModal from "./DetailModal";
import VoteModal from "./VoteModal";
import { CREATE_VOTER } from "../../../queries/queries.graphql";
import { VotingContext } from "../../../widgets/Voting";

import "./VoteProjectGallery.scss";

export default function VoteProject({ className, orientation }) {
	const { projects } = useContext(VotingContext);
	const [createVoter] = useMutation(CREATE_VOTER);

	function generateState(stateName) {
		switch (stateName) {
			case "detailOpened":
				return {
					detailModal: true,
					voteModal: false,
				};
			case "voteOpened":
				return {
					detailModal: false,
					voteModal: true,
				};
			default:
				return {
					detailModal: false,
					voteModal: false,
				};
		}
	}

	const [modal, setModal] = useState({
		machine: generateState("default"),
		voted: false,
		page_title_full: "",
		teaser: "",
		author: "",
		src: "",
		alt: "",
		btnLabel: "Проголосовать",
		competitionId: null,
	});

	const voted = get(modal, "voted", false);
	const page_title_full = get(modal, "page_title_full", "");
	const teaser = get(modal, "teaser", "");
	const author = get(modal, "author", "");
	const src = get(modal, "src", "");
	const alt = get(modal, "alt", "");
	const btnLabel = get(modal, "btnLabel", "");
	const competitionId = get(modal, "competitionId", "");

	const mediaSize = {
		"Landscape": ["100%", ""],
		"Portrait": ["100%", ""],
		"Square": ["100%", isMobile ? 300 : ""],
		"default": ["100%", isMobile ? 300 : 500],
	};
	const [width, height] = mediaSize[orientation || "default"];

	function addVote(id, page_title_full) {
		localStorage.setItem("competitionId", id);
		localStorage.setItem("voted", true);

		setModal({
			machine: generateState("voteOpened"),
			voted: true,
			btnLabel: "Вы проголосовали",
			competitionId: id,
		});

		createVoter({
			variables:
				{
					competitionProjectId: id,
				},
		})
			.then(() => {
				notification.success({
					message: `Вы проголосовали за ${page_title_full}`,
				});
			})
			.catch(createVoterError => {
				console.error("create voter error", { createVoterError });
				notification.error({
					message: "Вы уже проголосовали",
				});
			});
	}

	useEffect(() => {
		const competitionId = localStorage.getItem("competitionId");
		const voted = localStorage.getItem("voted");

		setModal({
			machine: generateState("default"),
			voted,
			btnLabel: voted ? "Вы проголосовали" : "Проголосовать",
			competitionId,
		});
	}, []);

	return (
		<div className={"row project-list"}>
			{
				projects && projects.map(({ main_image, page_title_full, teaser, author, id, votes }, idx) => {
					const src = get(main_image, "src", "");
					const alt = get(main_image, "alt", "");

					return (
						<div
							key={`project-list-item-${idx}`}
							className={`col-12 col-md-4 project-list__item d-flex flex-column justify-content-between align-items-center ${className}`}
						>
							<div
								className={"project-list__image-block mb-3"}
								onClick={() => setModal({
									machine: generateState("detailOpened"),
									voted,
									page_title_full,
									teaser,
									author,
									src,
									alt,
									btnLabel,
									competitionId,
								})}
							>
								<img
									src={src}
									alt={alt}
									width={width}
									height={height}
									className={"project-list__image"}
								/>
							</div>

							<p className={"project-list__title mb-3 h4"}>
								{page_title_full}
							</p>

							<Button
								type="primary"
								className={"mb-3 "}
								disabled={voted}
								icon={competitionId == id && "down-circle"}
								onClick={() => {
									addVote(id, page_title_full);
								}}
							>
								{btnLabel}
							</Button>

							<div className={"project-list__progress-bar d-flex flex-column align-items-end"}>
								<div className={"h4"}>
									{votes}%
								</div>
								<Progress percent={votes} showInfo={false} />
								<div
									className={"project-list__btn_detail"}
									onClick={() => setModal({
										machine: generateState("detailOpened"),
										voted,
										page_title_full,
										teaser,
										author,
										src,
										alt,
										btnLabel,
										competitionId,
									})}
								>
								Подробнее
								</div>
							</div>
						</div>
					);
				})
			}

			<DetailModal
				page_title_full={page_title_full}
				isModal={modal.machine.detailModal}
				setModal={() => setModal({
					machine: generateState("default"),
					voted,
					page_title_full,
					teaser,
					author,
					src,
					alt,
					btnLabel,
					competitionId,
				})}
				author={author}
				teaser={teaser}
				src={src}
				alt={alt}
			/>

			<VoteModal
				page_title_full={page_title_full}
				isModal={modal.machine.voteModal}
				setModal={() => setModal({
					machine: generateState("default"),
					voted,
					btnLabel,
					competitionId,
				})}
			/>

		</div>
	);
}
VoteProject.propTypes = {
	className: PropTypes.string,
	orientation: PropTypes.string,
};

VoteProject.defaultProps = {
	className: "",
	orientation: "",
};
