import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

import { useThemeContext } from "../../../Layout/ThemeContext";
import { removeEmptyKeys } from "../../../../helpers";

export default function DetailModal({ page_title_full, isModal, setModal, author, teaser, src, alt }) {
	const { theme } = useThemeContext();

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={page_title_full}
				visible={isModal}
				centered
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				onCancel={setModal}
				footer={null}
			>
				<div className={"d-flex flex-column justify-content-between"}>
					<img
						src={src}
						alt={alt}
						height={"auto"}
						width={"100%"}
						className={"mb-2"}
					/>
					<div className={"mb-3"}>
					Автор: {author}
					</div>
					<div>
						{teaser}
					</div>
				</div>
			</Modal>
		</>
	);
}

DetailModal.propTypes = {
	page_title_full: PropTypes.string,
	author: PropTypes.string,
	teaser: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	isModal: PropTypes.bool,
	setModal: PropTypes.func,
};

DetailModal.defaultProps = {
	page_title_full: "",
	author: "",
	teaser: "",
	src: "",
	alt: "",
	isModal: false,
	setModal: () => {},
};
