import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import "./Jury.scss";
import portret from "./portret.jpg";

export default function Jury({ items }) {
	return (
		<>
			<h3>
				Экспертное жюри
			</h3>
			<div className="row jury">
				{
					items.map((item, idx) => {
						const alt = get(item, "main_image.alt", "");
						const name = get(item, "name", "");
						const post = get(item, "post", "");

						return (
							<div className="col-12 col-md-6 jury__item mb-5" key={idx}>
								<div className={"jury__img"}>
									<img src={portret} alt={alt} />
								</div>
								<div>
									{name && <div className={"h4"}>
										{name}
									</div>}
									{post && <div className={"text"}>
										{post}
									</div>}
								</div>
							</div>
						);
					})
				}
			</div>
		</>
	);
}
Jury.propTypes = {
	items: PropTypes.array,
};
Jury.defaultProps = {
	items: [],
};

