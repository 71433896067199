import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { get, compact, last } from "lodash";

import { ContentBlock } from "../../components/Blocks";
import VotingTabs from "../../components/Voting/Tabs";
import Pages from "../../components/Pages";
import Contents from "../../components/Contents";

import { Share } from "../../widgets";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import {
	VOTINGS,
	VOTINGS_MAX_UPDATE,
} from "../../queries/queries.graphql";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { VotingProvider } from "../../widgets";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query votingQuery($slug: String!) {
		hasura {
			...Votings
		}
	}
`;

export default function Voting({ location, data }) {
	const { theme } = useThemeContext();

	const [voting, setVoting] = useState(get(data, "hasura.votings[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.v_votings_updated_at[0].updated_at", new Date()));
	const path = last(compact(location.pathname.split("/")));

	const votingOptions = {
		variables: {
			location: path,
		},
		displayName: "votingsQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const {
		loading: maxUpdateLoading,
		data: maxUpdateData,
		error: maxUpdateError,
	} = useQuery(VOTINGS_MAX_UPDATE, votingOptions);

	const [loadVoting, {
		called: votingCalled,
		loading: votingLoading,
		data: votingData,
		error: votingError,
	}] = useLazyQuery(VOTINGS, votingOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_votings_updated_at_aggregate.max.updated_at"));
		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadVoting();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load VotingMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (votingData && !votingError) {
			setVoting(get(votingData, "votings[0]", voting));
			removePreloader(votingCalled);
		} else if (votingError) {
			console.error("Invalid load VotingData", { votingError });
			removePreloader();
		}
	}, [votingLoading]);

	const content_blocks = get(voting, "content_blocks", []);
	const stages = get(voting, "stages", []);
	const page_title_full = get(voting, "page_title_full", "");
	const jury = get(voting, "jury", "");
	const slug = get(voting, "slug", "");
	const url = makeUrl.voting({ slug });

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<VotingProvider>
			<Pages
				entity={voting}
				url={url}
			>
				<ContentBlock key={"main-content"}>
					<div className={"container mt-5"}>
						<h1 className={"h1"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								lineHeight: theme.lineHeight.h1,
								color: theme.color.text,
							})}
						>
							{page_title_full}
						</h1>
						<Contents items={content_blocks}/>
					</div>
				</ContentBlock>
				<ContentBlock key={"main-content"}>
					<div className={"container"}>
						<VotingTabs items={stages} jury={jury}/>
					</div>
				</ContentBlock>
				<Share
					url={url}
					pageTitleShort={"Голосование"}
				/>
			</Pages>
		</VotingProvider>
	);
}

Voting.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

Voting.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
};
