import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Button, notification, Form, Radio, Select, Input } from "antd";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import { useThemeContext } from "../../../Layout/ThemeContext";
import { UPDATE_VOTER } from "../../../../queries/queries.graphql";
import { removeEmptyKeys } from "../../../../helpers";

const { Option } = Select;

export function VoteModal({ form, page_title_full, isModal, setModal }) {
	const { getFieldDecorator, getFieldsValue } = form;
	const formItemLayout = {
		labelCol: { span: 7 },
		wrapperCol: { span: 17 },
	};
	const { theme } = useThemeContext();
	const width = isMobile ? 300 : 1020;

	const [updateVoter] = useMutation(UPDATE_VOTER);

	function updateVoterData() {
		setModal();

		updateVoter({
			variables: getFieldsValue(),
		})
			.then(() => {
				notification.success({
					message: "Форма отправлена",
				});
			})
			.catch(createVoterError => {
				console.error("create voter error", { createVoterError });
				notification.error({
					message: "Ответы не заполнены",
				});
			});
	}

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={page_title_full}
				visible={isModal}
				centered
				width={width}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				onCancel={setModal}
				footer={[
					<Button key="back" onClick={setModal}>
					Выход
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={updateVoterData}
					>
					Отправить
					</Button>,
				]}
			>
				<div className={"py-3 px-3"}>
					<div className={"mb-4"}>
					Спасибо за Ваш голос! <br/>
					Мы будем признательны, если Вы ответите на несколько вопросов.
					Это не займет у Вас больше минуты, а Ваши ответы помогут нам в дальнейшем.
					</div>

					<Form {...formItemLayout} layout={"vertical"}>
						<Form.Item label={"Пол"}>
							{getFieldDecorator("gender")(
								<Radio.Group>
									<Radio value="man">женский</Radio>
									<Radio value="woman">мужской</Radio>
								</Radio.Group>,
							)}
						</Form.Item>
						<Form.Item label="Возраст" hasFeedback>
							{getFieldDecorator("age")(
								<Select placeholder="Выберите возраст">
									<Option value="18">менее 18 лет</Option>
									<Option value="18-25">18-25 лет</Option>
									<Option value="26-35">26-35 лет</Option>
									<Option value="36-44">36-44 лет </Option>
									<Option value="45-54">45-54 лет</Option>
									<Option value="55-64">55-64 лет</Option>
									<Option value="65+">65 и более</Option>
								</Select>,
							)}
						</Form.Item>
						<Form.Item label="Город проживания" {...formItemLayout}>
							{getFieldDecorator("city")(
								<Input placeholder="Введите город" />,
							)}
						</Form.Item>
						<Form.Item label="Род деятельности" hasFeedback>
							{getFieldDecorator("activity")(
								<Select placeholder="Выберите род деятельности">
									<Option value="Студент">Студент</Option>
									<Option value="Работающий по найму">Работающий по найму</Option>
									<Option value="Предприниматель">Предприниматель</Option>
									<Option value="Безработный">Безработный</Option>
									<Option value="Пенсионер">Пенсионер</Option>
								</Select>,
							)}
						</Form.Item>
						<Form.Item label={"Семейное положение"}>
							{getFieldDecorator("maritalStatus")(
								<Radio.Group>
									<Radio value="не женат/не замужем">не женат/не замужем</Radio>
									<Radio value="в отношениях">в отношениях</Radio>
									<Radio value="женат/замужем">женат/замужем</Radio>
								</Radio.Group>,
							)}
						</Form.Item>
						<Form.Item label={"Наличие детей"}>
							{getFieldDecorator("kids")(
								<Radio.Group>
									<Radio value="0">детей нет</Radio>
									<Radio value="1">один ребенок</Radio>
									<Radio value="2-5">2-5 детей</Radio>
								</Radio.Group>,
							)}
						</Form.Item>
						<Form.Item label="Как часто Вы путешествуете?" hasFeedback>
							{getFieldDecorator("travelFrequency")(
								<Select>
									<Option value="ежемесячно">ежемесячно</Option>
									<Option value="один раз в полгода и чаще">один раз в полгода и чаще</Option>
									<Option value="один раз в год и чаще">один раз в год и чаще</Option>
									<Option value="реже, чем один раз в год">реже, чем один раз в год</Option>
								</Select>,
							)}
						</Form.Item>
						<Form.Item label="Где Вы обычно путешествуете?" hasFeedback>
							{getFieldDecorator("travelPlaces")(
								<Select>
									<Option value="внутри своего региона">внутри своего региона</Option>
									<Option value="по России">по России</Option>
									<Option value="в другие страны">в другие страны</Option>
									<Option value="все три направления">все три направления</Option>
								</Select>,
							)}
						</Form.Item>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default Form.create({ name: "voting" })(VoteModal);

VoteModal.propTypes = {
	page_title_full: PropTypes.string,
	isModal: PropTypes.bool,
	setModal: PropTypes.func,
	form: PropTypes.object,
};

VoteModal.defaultProps = {
	page_title_full: "",
	isModal: false,
	setModal: () => {},
	form: PropTypes.object,
};
